import {
  createBrowserRouter,
  type LoaderFunctionArgs,
  redirect,
} from 'react-router-dom';
import * as login from './components/login.component';
import * as table from './routes/table';
import * as item from './routes/item';
import * as docs from './routes/docs';
import * as other from './routes/other';
import * as home from './routes/home';
import authService from './services/auth.service';
import Layout from './components/layout.component';

const basename = '/frontend/';

function protectedLoader({ request }: LoaderFunctionArgs): any {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication

  if (!authService.isUserLoggedIn()) {
    const params = new URLSearchParams();
    const currentPath = new URL(request.url).pathname;

    // remove basename from current_path so that redirects work correctly
    if (currentPath.startsWith(basename)) {
      params.set('from', currentPath.slice(basename.length - 1));
    } else {
      params.set('from', currentPath);
    }

    return redirect('/login?' + params.toString());
  }
  return null;
}

export const router = createBrowserRouter(
  [
    {
      id: 'root',
      path: '/',
      loader: protectedLoader,
      Component: Layout,
      children: [
        {
          path: 'g/:categoryName/:itemName/table',
          Component: table.TablePage,
        },
        {
          path: 'g/:categoryName/:itemName/create',
          Component: table.CreatePage,
        },
        {
          path: 'g/:categoryName/:itemName/entity/:itemId',
          Component: item.ItemPage,
        },
        {
          path: 'docs/api',
          Component: docs.ApiPage,
        },
        {
          path: 'docs/info',
          Component: docs.InfoPage,
        },
        {
          path: 'other/flower',
          Component: other.FlowerPage,
        },
        {
          path: 'other/mailcatcher',
          Component: other.MailCatcherPage,
        },
        {
          path: '',
          Component: home.HomePage,
        },
      ],
    },
    {
      path: '/login',
      loader: login.loginLoader,
      Component: login.LoginPage,
    },
    {
      path: '/logout',
      async loader() {
        authService.logout();
        return redirect('/login');
      },
    },
  ],
  { basename },
);

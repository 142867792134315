import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { useGetData } from '../../hooks/useGetData';
import DeprecatedDataService from '../../services/deprecated-data.service';
import React from 'react';

const ApiPage = (): React.ReactElement => {
  const [apiSpec, isLoading, failed] = useGetData(
    async () => await DeprecatedDataService.getApiSpec(),
  );

  if (isLoading) {
    // TODO animated spinner
    return <div>Loading data...</div>;
  }

  if (failed !== '' || apiSpec === undefined) {
    return <div>Failed loading data: {failed}</div>;
  }

  const h = URL.createObjectURL(new Blob([JSON.stringify(apiSpec)]));

  return (
    <div>
      <a
        className={'btn btn-primary ms-4 mt-3'}
        href={h}
        download={'apispec.json'}
      >
        Download specification
      </a>
      <SwaggerUI spec={apiSpec} />
    </div>
  );
};

export default ApiPage;
